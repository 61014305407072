const theme = {
    initialColorModename: 'light',
    colors: {
        text: '#666',
        secondaryText: '#333',
        background: '#fff',
        primary: '#8954A8',
        modes: {
            dark: {
                text: '#fff',
                secondaryText: 'fefefe',
                background: '#2c2c2c',
                primary: '#8954A8',
            }
        }
    },
    fonts: {
        body: "-apple-system, Roboto, sans-serif, serif",
        heading: "-apple-system, Roboto, sans-serif, serif",
    },
    fontSizes: [
        "1rem", "1.2rem", "1.5rem", "2rem", "3rem", "3.5rem", "4rem", "5rem"
    ],
    lineHeights: {
        body: "1.7rem",
        heading: "1.5rem",
    }
}

export default theme